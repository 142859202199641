<template>
    <div>
            <br>
      <br>
      <div id="section-2"  v-view class="row carrieres computer "  data-aos="fade-up" data-aos-duration="3000">
        <div class="col-sm-12  col-md-4   car car-1 d-flex justify-content-center flex-column align-self-center">
          <div class="title">
              <div class="text-center">
                <img style="height:100px" src="@/assets/images/import/pr/interview-640_white.png" alt="" srcset="">
            </div>
            <!-- titre -->
            <div class="text-center txt-carrieres Tangier_Medium">
              <tt-editor v-if="editable" :value="title_1" @input="changeText($event,'title_1')" ></tt-editor>
              <div   v-else v-html="title_1">
              </div>
            </div>
          </div>
          <!-- description -->
          <div class="text-hover" >
              <tt-editor  v-if="editable" :value="description_1" @input="changeText($event,'description_1')" ></tt-editor>
              <div  v-else v-html="description_1">
              </div>
          </div>
        </div>
        <div class="col-sm-12  col-md-4 car car-2 d-flex justify-content-center flex-column align-self-center "  >
          <div class="title">
            <div class="text-center">
                <img class="icon" src="@/assets/images/import/pr/gestion carrière.png" alt="" srcset="">
            </div>
            <div class="text-center txt-carrieres Tangier_Medium" >
              <tt-editor v-if="editable" :value="title_2" @input="changeText($event,'title_2')" ></tt-editor>
              <div v-else v-html="title_2">
              </div>
            </div>
          </div>
           <div class="text-hover" >
              <tt-editor v-if="editable" :value="description_2" @input="changeText($event,'description_2')" ></tt-editor>
              <div v-else v-html="description_2">
              </div>
            </div>
        </div>
        <div class="col-sm-12  col-md-4 car car-3 d-flex justify-content-center flex-column align-self-center">
          <div class="title">
            <div class="text-center ">
                <img class="icon " src="@/assets/images/import/pr/gestion_administrative.png" alt="" srcset="">
            </div>
            <div class="text-center  txt-carrieres Tangier_Medium">
              <tt-editor v-if="editable" :value="title_3" @input="changeText($event,'title_3')" ></tt-editor>
              <div v-else v-html="title_3">
              </div>
            </div>
          </div>
          <div class="text-hover">
              <tt-editor v-if="editable" :value="description_3" @input="changeText($event,'description_3')" ></tt-editor>
              <div v-else v-html="description_3">
              </div>
          </div>
        </div>
      </div>

      <div  class="row carrieres-mobile m-2 mobile"  >
        <div data-aos="fade-up" data-aos-duration="3000" class="col-sm-12 m-2  car-mobile car-1 d-flex justify-content-center flex-column align-self-center">
          <div class="title-mobile">
              <div class="text-center">
                <img style="height:100px" src="@/assets/images/import/pr/interview-640_white.png" alt="" srcset="">
            </div>
            <div class="text-center txt-carrieres Tangier_Medium" >
              <tt-editor v-if="editable" :value="title_1" @input="changeText($event,'title_1')" ></tt-editor>
              <div v-else v-html="title_1">
              </div>
            </div>
          </div>
          <div class="text-1" >
              <tt-editor v-if="editable" :value="description_1" @input="changeText($event,'description_1')" ></tt-editor>
              <div v-else v-html="description_1">
              </div>
            </div>
        </div>
        <div data-aos="fade-up" data-aos-duration="3000" class="col-sm-12 m-2  car-mobile car-2 d-flex justify-content-center flex-column align-self-center "  >
          <div class="title-mobile">
            <div class="text-center">
                <img class="icon" src="@/assets/images/import/pr/gestion carrière.png" alt="" srcset="">
            </div>
            <div class="text-center txt-carrieres Tangier_Medium" >
              <tt-editor v-if="editable" :value="title_2" @input="changeText($event,'title_2')" ></tt-editor>
              <div v-else v-html="title_2">
              </div>
            </div>
          </div>
           <div class="text-1" >
              <tt-editor v-if="editable" :value="description_2" @input="changeText($event,'description_2')" ></tt-editor>
              <div v-else v-html="description_2">
              </div>
            </div>
        </div>
        <div data-aos="fade-up" data-aos-duration="3000" class="col-sm-12 m-2  car-mobile car-3 d-flex justify-content-center flex-column align-self-center">
          <div class="title-mobile">
            <div class="text-center ">
                <img class="icon " src="@/assets/images/import/pr/gestion_administrative.png" alt="" srcset="">
            </div>
            <div class="text-center  txt-carrieres Tangier_Medium" >
              <tt-editor v-if="editable" :value="title_3" @input="changeText($event,'title_3')" ></tt-editor>
              <div v-else v-html="title_3">
              </div>
            </div>
          </div>
          <div class="text-1">
              <tt-editor v-if="editable" :value="description_3" @input="changeText($event,'description_3')" ></tt-editor>
              <div v-else v-html="description_3">
              </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import ttEditor from './tt-editor.vue';

export default {
  name: "carriere",
    components: {
    ttEditor
  },
  data() {
    return {
     
      getdescription: null,
    };
  },
  computed: {},
  watch: {
    justify(data) {
      console.log(data);
    },
  },
  props: {
    title_1: String,
    title_2: String,
    title_3: String,
    description_1: String,
    description_2: String,
    description_3: String,
    image: String,
    editable: Boolean,
    sectionTitle: String,
  },
  methods: {
    format(description) {
      let justify = description.data["paragraph-justify"];
      return justify;
    },
    saveImage(image) {
      this.$emit("change-image-hb", image);
    },
    typography(j, h) {
      return j + " " + h;
    },
    textTypographie(elem, data) {
      if (data["type"] == "size") {
        elem.data["paragraph-font-size"] = data["value"];
      }
      if (data["type"] == "justify") {
        elem.data["paragraph-font-justify"] = data["value"];
      }
      if (data["type"] == "weight") {
        elem.data["paragraph-font-weight"] = data["value"];
      }
      if (data["type"] == "style") {
        elem.data["paragraph-font-style"] = data["value"];
      }
    },
    changeText(payload,element){
       this.$emit('ChangeTextPrCarriere',{
         'element':element,
         'data':payload
       })
    }
  },
};
</script>

<style scoped>
.carrieres {
  padding-left: 80px;
  padding-right: 80px;
}

@media screen and (min-width: 992px) {
  /**COMPUTER STYLE */

  /** CARRIERE */
  .carrieres > div {
    height: 390px;
  }

  .title{
    transition:all 0.5s ease-in-out;
    margin-top:40%;
  }

}

@media screen and (max-width: 992px) {
  /**MOBILE STYLE */


  /** CARRIERE */
  .carrieres > div{
    min-height: 390px;
  }

  .title{
    margin-top: 50px;
  }

}

.car-1 {
  background-image: linear-gradient(138deg, rgba(158,94,74,0.9247898988697041) 33%, rgba(201,121,14,0.7847338764607406) 95%, rgba(253,123,0,0.5970588064327293) 100%),
    url("../../assets/images/import/pr/car-1.jpg");
  background-size: cover;
}
.car-1:hover {
  background-image: linear-gradient(#876b55), url("../../assets/images/import/pr/car-1.jpg"); 
  
}
.car:hover .text-hover{
  transition:all 0.1s;
  visibility: visible;
  margin-bottom:  0rem;
  opacity: 1;
}

.txt-carrieres {
  font-size: 38px !important;
  font-weight: 500 !important;
  color: white !important;
}

.car:hover .title{
  margin-top:0%;

}

.car .text-hover{
  /* display: none; */
  /* margin-top: 15rem; */
  visibility: hidden;
  transition: all 2s;
  opacity: 0;
}

::v-deep .text-hover *{
  font-family: "Noto Sans" !important;
  color:#fff;
  text-align: center;
  line-height: 40px !important;
}
.car-2 {
  background-color: #876d55;
  background-image:   linear-gradient(138deg, rgba(158,94,74,0.9247898988697041) 33%, rgba(201,121,14,0.7847338764607406) 95%, rgba(253,123,0,0.5970588064327293) 100%),
    url("../../assets/images/import/pr/car-2.jpg");
  background-size: cover;
}
.car-2:hover {
  background-image: linear-gradient(#876b55), url("../../assets/images/import/pr/car-2.jpg");
}
.car-3 {
  background-color: #876d55;
  background-image: linear-gradient(138deg, rgba(158,94,74,0.9247898988697041) 33%, rgba(201,121,14,0.7847338764607406) 95%, rgba(253,123,0,0.5970588064327293) 100%),
    url("../../assets/images/import/pr/car-3.jpg");
  background-size: cover;
}
.car-3:hover {
  background-image: linear-gradient(#876b55), url("../../assets/images/import/pr/car-3.jpg");
}


.txt-carrieres{
  /* font-family:'Script MT Bold'; */
  font-size: 38px;
  font-weight: 500;
  color: white;
}
::v-deep .txt-carrieres h1,::v-deep .txt-carrieres h2{
  font-family: tangier, sans-serif !important;
}
</style>